<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 ">


            <div class="row mt-0">
              <div class="col-6">
                <h4>
                  <router-link :to="{name:'students'}">
                    <i class="fas fa-arrow-left"></i>
                  </router-link>
                  Assign Optional Courses
                </h4>
              </div>

            </div>




            <v-row class="text-center mt-0">
              <div class="col-lg-12 text-right">
                <b-nav class="">
                  <b-nav-item active  v-if="search.class_id && this.selected.length>0" @click.prevent="assignOptionalCourse">
                    <i class="fas fa-plus lg3 fa-2x" title="Assign Optional Course"></i>
                  </b-nav-item>
                </b-nav>

              </div>



              <v-col cols="12" class="text-center mt-0">


                  <v-form class="form" id="kt_password_change_form">
                    <div class="form-group row">

                      <div class="col-lg-2">
                        <v-select
                            :items="levels"
                            class="form-control"
                            v-model="search.level_id"
                            label="Level"
                            item-value="id"
                            item-text="title"
                            outlined
                            @change="getPrograms"
                            dense>
                        </v-select>
                      </div>
                      <div class="col-lg-2">
                        <v-select
                            outlined
                            dense
                            class="form-control"
                            v-model="search.program_id"
                            :items="programs"
                            label="Programs"
                            item-value="id"
                            item-text="title"
                            @change="getGrades"
                        >
                        </v-select>

                      </div>

                      <div class="col-lg-2">
                        <v-select
                            :items="academicYears"
                            class="form-control"
                            v-model="search.academic_year_id"
                            label="Academic Year"
                            item-value="id"
                            item-text="year"
                            outlined
                            dense>
                        </v-select>
                      </div>
                      <div class="col-lg-2">
                        <v-select
                            outlined
                            dense
                            class="form-control"
                            v-model="search.grade_id"
                            :items="gradesLevels"
                            label="Semester/Year"
                            item-value="id"
                            item-text="title"
                            @change="getAcademicClasses"
                        ></v-select>

                      </div>
                      <div class="col-lg-2">
                        <v-select
                            outlined
                            dense
                            class="form-control"
                            v-model="search.class_id"
                            :items="academic_classes"
                            label="Class Name"
                            item-value="id"
                            item-text="title"
                        ></v-select>

                      </div>
                      <div class="col-lg-1">
                        <button
                            @click.prevent="getUsers"
                            type="submit"
                            class="form-control form-control-lg btn btn-success font-size-lg"
                            name="search"
                            ref="search"
                        >Search
                        </button>
                      </div>
                      <div class="col-lg-1">
                        <button
                            class="form-control form-control-lg btn btn-danger font-size-lg"
                            @click.prevent="resetFilter"

                        >Reset
                        </button>
                      </div>




                    </div>
                  </v-form>



              </v-col>
            </v-row>
            <div class="col-12">



              <table class="table table-stripe">
                <thead>
                <th>
                  <v-checkbox @change="selectAll()" v-model="selectAllData" outlined dense
                              label="Full Name"></v-checkbox>
                </th>
                <!--                                    <th>Full Name</th>-->
                <th>Symbol Number</th>
                <th>Program</th>
                <th>Semester/Year</th>
                <th>Optional Courses</th>
                <th>Class</th>
                <th>Status</th>
                <th>Action</th>
                </thead>
                <tbody>
                <tr v-for="(user, index) of users" :key="index">
                  <!--                                        <td>-->

                  <!--                                        </td>-->
                  <td>
                    <v-checkbox v-model="selected" :value="index" outlined
                                dense v-bind:label="user.full_name"></v-checkbox>
                  </td>
                  <td>{{ user.setting ? user.setting.symbol_no : "N/A" }}</td>
                  <td>{{ user.setting ? user.setting.program_title : "N/A" }}</td>
                  <td>{{ user.setting ? user.setting.current_semester_title : "N/A" }}</td>
                  <td>
                    <span v-if="user.setting.optional_courses">
                        <ul>
                      <li  v-for="(opt, i) of user.setting.optional_courses" :key="i">
                        {{opt.title}}
                      </li>
                    </ul>

                    </span>
                    <span v-else>NA</span>
                  </td>
                  <td>{{ user.setting ? user.setting.current_class_title : "N/A" }}</td>
                  <td>
                    <span
                        :class="user.is_active?'badge badge-success':'badge badge-danger'">{{ user.is_active ? 'Active' : 'Inactive' }}</span>
                  </td>
                  <td>
                    <div class="kt-widget__toolbar">

                      <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown"
                          no-caret
                          right
                          no-flip
                          text="Actions"

                      >
                        <template v-slot:button-content>
                          <a href="#" class="" data-toggle="dropdown">
                            <!--              <span class="svg-icon svg-icon-success svg-icon-2x">-->
                            <!--begin::Svg Icon-->
                            <i class="flaticon-more-1"></i>
                            <!--end::Svg Icon-->
                            <!--              </span>-->
                          </a>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">


                          <b-dropdown-text tag="div" class="navi-item">

                            <router-link v-if="user.type"
                                         :to="{name:'students-summary', params:{id:user.id, type:user.type[0]}}">
                              <a href="#" class="navi-link"
                              >
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </router-link>
                          </b-dropdown-text>

                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </div>

                  </td>
                </tr>
                </tbody>
              </table>
              <div class="col-12 text-right">


                <v-pagination
                    v-if="this.users.length>0"
                    v-model="page"
                    @input="getUsers"
                    :length="pageCount"
                    :total-visible="7"
                    :total-rows="total"
                    :per-page="perPage"
                ></v-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

    <v-dialog
        v-model="dialog" max-width="900"
    >
      <v-card>
        <v-card-title>
          Assign Optional Courses for {{ grade ? grade.title : '' }}
        </v-card-title>
        <v-card-text>
          <div class="row mt-5">
            <div class="col-12">
              <v-row>

                <div class="col-12">
                  <v-select
                      :items="optional_courses"
                      v-model="selected_optional_courses"
                      outlined
                      multiple
                      dense
                      item-value="id"
                      item-text="title"
                      label="Optional Courses"
                  >
                  </v-select>

                </div>


                <div class="col-12 text-right mt-4">
                  <v-btn
                      color="red"
                      class="btn btn-standard"
                      depressed
                      @click="resetForm"
                  >Cancel
                  </v-btn>
                  <v-btn
                      class="text-white ml-2 btn btn-primary"
                      depressed
                      @click="saveOptionalCourses"
                      :loading="isBusy"
                  >Save
                  </v-btn>

                </div>
              </v-row>
              <!--                    </v-tab-item>-->
              <!--                    <v-tab-item class="mt-5">-->
              <!--                      <v-multiselect-listbox :options="['Software Engineer', 'Java']"></v-multiselect-listbox>-->
              <!--                    </v-tab-item>-->
              <!--                  </v-tabs>-->
            </div>
          </div>
        </v-card-text>
      </v-card>


    </v-dialog>
  </v-app>
</template>
<script>
import UserService from "@/core/services/user/UserService";
import ProgramService from "@/core/services/level/program/ProgramService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import CourseService from "@/core/services/courses/CoursesService";
import StudentSetting from "@/core/services/user/student/StudentSettingService";

const userService = new UserService();
const programService = new ProgramService();
const gradeLevelService = new GradeLevelService();
const academicClassService = new AcademicClassService();
const academicYearService = new AcademicYearService();
const levelService = new LevelService();
const courseService = new CourseService();
const studentSetting = new StudentSetting();
export default {
  name: "users",
  components: {},
  data() {
    return {
      import_excel: false,
      isLoading: false,
      excel_file: null,
      optional_courses: [],
      dialog: false,
      selected_optional_courses: [],
      search: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        sort_by:'asc',
        type: 'student',
        limit: 50,

      },
      isBusy:false,
      grade: null,
      pageCount: null,
      users: [],
      selected: [],
      selected_students: [],
      programs: [],
      academicYears: [],
      gradesLevels: [],
      academic_classes: [],
      levels: [],
      levelId: null,
      page: null,
      total: null,
      selectAllData: false,
      perPage: null
    };
  },
  mounted() {
    if (this.$route.query.first_name) {
      this.search = this.$route.query;
    }


    this.getLevels()
    this.getAcademicYears()
  },
  methods: {
    resetFilter() {
      this.search = {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        academic_year_id: '',
        level_id: '',
        program_id: '',
        grade_id: '',
        class_id: '',
        type: 'student',
        limit: 25
      }
      // this.getUsers();


    },

    selectAll() {

      if (this.selected.length > 0) {
        this.selected = [];
        this.selected_students = [];
      } else {
        if(this.selectAllData)
        {
          for (let index in this.users) {
            this.selected.push(parseInt(index));

            this.selected_students.push(this.users[index])
          }
        }


      }

    },

    getUsers() {
      if(this.search.class_id)
      {
        this.$bus.emit('toggleLoader',);
        userService.paginate(this.search, this.page).then(response => {
          this.selected = [];
          this.$bus.emit('toggleLoader',);
          this.users = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.pageCount = response.data.meta.last_page;
          this.selectAllData = false;
        });
      }
      else
      {
        this.$snotify.warning('Please select a class.');
      }
    },
    editUser(id) {
      return this.$router.push({name: "students-update", params: {id: id}});
    },
    getLevels() {
      levelService.all().then(response => {
        this.levels = response.data;
        if (this.search.id && this.search.level_id) {
          this.getPrograms();
        }
      });
    },
    getPrograms() {
      programService.getAllByLevelId(this.search.level_id).then(response => {
        this.programs = response.data;
      });
    },
    getAcademicYears() {
      academicYearService.all().then(response => {
        this.academicYears = response.data;

      });
    },
    getGrades() {
      gradeLevelService.getByProgram(this.search.program_id).then(response => {
        this.gradesLevels = response.data;

      });
    },

    getGrade() {
      gradeLevelService.show(this.search.grade_id).then(response => {
        this.grade = response.data.grades;

      });
    },
    getAcademicClasses() {

      academicClassService.getByProgramLevel(this.search.academic_year_id, this.search.program_id, this.search.grade_id).then(response => {
        this.academic_classes = response.data;
      });
    },

    getOptionalCourses() {

      courseService.getOptionalCourseByProgramAndGrade(this.search.program_id, this.search.grade_id).then(response => {
        this.optional_courses = response.data.courses;

      });
    },
    assignOptionalCourse() {
      this.dialog = true;
      this.selected_students = [];
      this.getGrade();
      this.getOptionalCourses();
      if (this.selected.length > 0) {

        for (let i = 0; i < this.selected.length; i++) {
          this.selected_students.push(this.users[this.selected[i]])
        }
      }


    },

    saveOptionalCourses() {
      let studentIds = [];
      if (this.selected_students.length > 0) {

        for (let i = 0; i < this.selected.length; i++) {
          this.selected_students.push(this.users[this.selected[i]])
          studentIds.push(this.users[this.selected[i]].id)
        }

        let data = {
          'class_id': this.search.class_id,
          'program_id': this.search.program_id,
          'grade_id': this.search.grade_id,
          'academic_year_id': this.search.academic_year_id,
          'student_ids': studentIds,
          'optional_courses_id': this.selected_optional_courses,
        };

       studentSetting.saveOptionalCourses(data).then(response => {
          this.dialog = false;
          this.$snotify.success('Optional courses Information updated.');
          this.getUsers();
          this.optional_courses = [];
          this.selected_students = [];
          this.selected_optional_courses = [];
        });

      }

    },

    deleteUser(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            userService
                .delete(id)
                .then(response => {
                  this.$snotify.success("Information deleted");

                  this.getSlider();
                })
                .catch(error => {
                  //console.log(error);
                });
          }
        }
      });
    },
    resetForm() {
      this.dialog = false;
      this.optional_courses = [];
      this.selected_students = [];
    },
    manageUserRole(id) {
      this.$router.push({name: "user-role", params: {id, id}});
    },

  }
};
</script>

<style scoped>
.form-control {
  border: none !important;
}
</style>
